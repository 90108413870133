.header {
	height: 50px;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #505050;
	background: #2c2c2c;
	box-shadow: 0 2px 5px -2px #333;
	padding-left: 8px;
	box-sizing: border-box;
}

.header-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 1px;
	font-size: 21px;
	color: #fff7f3;
	font-family: "Roboto", sans-serif;
}

.header-logo > .fa-camera-retro {
	color: #fff7f3;
	font-size: 25px;
	margin-right: 7px;
	margin-bottom: 5px;
}

.header-logo > .auth-hide > .fas {
	margin-left: 6px;
	font-size: 20px;
	color: #fff7f3;
	position: relative;
	top: 1px;
}

.fa-images,
.fa-tree,
.fa-road,
.fa-suitcase,
.fa-sun,
.fa-moon {
	font-size: 20px;
	color: #b5cbee;
	margin: 0 5px 0 0px;
	transition: all 0.2s ease;
}

.menu-text {
	margin-left: 4px;
}

.fa-cog {
	margin-top: 1px;
}

.fa-signature {
	margin-left: 8px;
}

.auth {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #b5cbee;
}

.menu-wrapper {
	height: 22px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: #2c2c2c;
	color: #b5cbee;
	margin-right: 8px;
	font-size: 20px;
	padding: 5px;
	border-radius: 3px;
	border: 1px solid #b5cbee;
	font-family: "Roboto", sans-serif;
	transition: all 0.2s ease;
}

.menu-wrapper:hover,
.menu-wrapper-active {
	background: #2c2c2c;
	color: #5d91e8;
	border: 1px solid #5d91e8;
	transition: all 0.2s ease;
}

.menu-wrapper:hover > i {
	background: #2c2c2c;
	color: #5d91e8;
	transition: all 0.2s ease;
}

.menu {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.nav {
	position: fixed;
	right: 0;
	top: 50px;
	width: 280px;
	height: 100vh;
	border-left: 10px solid #2c2c2c;
	background-image: linear-gradient(315deg, #333 0%, #222222 74%);
	transform: translateX(100%);
	transition: transform 0.2s ease-in-out;
}
.nav-view {
	transform: translateX(0);
}

.fa-arrow-circle-left {
	font-size: 20px;
	color: #333;
	margin-right: 5px;
	transition: all 0.2s ease;
}

.rotate-arrow {
	transform: rotateY(-180deg);
	transition: all 0.2s ease;
}

.nav > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	color: #fff7f3;
	font-size: 18px;
	cursor: pointer;
	border-bottom: 1px dashed #5a5a5a;
	transition: all 0.2s ease;
}

.nav > div:hover > i {
	color: #5d91e8;
	transition: all 0.2s ease;
}

.menu-active {
	background: #484848;
	transition: all 0.2s ease;
}
