.image-large {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  font-family: "Montserrat", sans-serif !important;
}

.image-mobile-controls {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 7px 7px 0 0;
  box-sizing: border-box;
  display: none;
  letter-spacing: 1px;
}

.image-mobile-controls-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.image-mobile-play {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.image-mobile-play > i {
  color: #333 !important;
  font-size: 40px;
}

.image-mobile-close > .fa-times-circle {
  color: #333;
  font-size: 30px;
}

.image-large-fade {
  animation: fadeOutImage 0.1s linear forwards;
}

.image-overlay-inner:hover > .image-overlay-close,
.image-overlay-inner:hover ~ .image-overlay-exif-wrapper,
.image-overlay-inner:hover > .image-overlay-controls {
  opacity: 1;
  transition: all 0.4s ease;
}

.image-overlay-inner:hover > .image-overlay-next > .fa-caret-right,
.image-overlay-inner:hover > .image-overlay-prev > .fa-caret-left {
  opacity: 1;
  transition: all 0.2s ease;
}

.image-overlay-inner {
  height: 100%;
}

.image-overlay-close:hover > i {
  opacity: 1;
}

.image-large-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 50px;
}

.landscape-large,
.portrait-large {
  max-width: 100%;
  box-sizing: border-box;
  padding: 50px 0px;
}

.landscape-large {
  max-height: 100vh;
  height: auto;
}

.image-fade {
  animation: fadeIn 0.3s linear forwards;
}

.portrait-large {
  max-height: 100vh;
  width: auto;
}

.image-overlay {
  animation: fadeIn 0s linear forwards;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 2;
}

.image-overlay-exif-wrapper > div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 142px;
}

.image-overlay-count {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 18px;
  padding: 10px;
}

.image-overlay-exif-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  bottom: 3px;
  color: #fff7f3;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: opacity 0.2s ease;
}

.image-overlay-exif-inner:hover {
  opacity: 0;
}

.image-overlay-exif-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  color: #fff7f3;
  padding: 10px;
  font-size: 18px;
  box-sizing: border-box;
}

.image-overlay-exif-inner > div {
  flex: 1;
  height: 100%;
}

.image-overlay-exif-inner-exif > div:first-child {
  margin: 0 0 8px 0;
}

.image-overlay-exif-inner-exif > div:last-child {
  margin: 8px 0 0 0;
}

.image-overlay-exif-inner-exif > div {
  font-style: italic;
  margin: 8px 0;
}

.fa-play {
  cursor: pointer;
  color: #fff7f3;
  font-size: 31px;
  transition: all 0.2s ease;
}

.fa-play:hover {
  color: #5d91e8;
  transition: all 0.2s ease;
}

.image-overlay-backwards {
  width: 80px;
  padding-left: 15px;
}

.image-overlay-forward {
  padding-right: 15px;
}

.image-overlay-backwards,
.image-overlay-play,
.image-overlay-forward {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 56px;
}

.image-overlay-controls-close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  flex: 1;
}

.image-overlay-controls-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  padding-left: 15px;
}

.image-overlay-controls-seconds {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 40px;
  background: #feffff;
  color: #333;
  transition: all 0.2s ease;
}

.image-overlay-controls-timer-active {
  background: #5d91e8 !important;
  color: #fff7f3 !important;
  user-select: none;
  pointer-events: none;
}

.image-overlay-controls-seconds-wrapper
  > .image-overlay-controls-seconds:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.image-overlay-controls-seconds-wrapper
  > .image-overlay-controls-seconds:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.image-overlay-controls-seconds-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: -223px;
}

.show-interval {
  margin-left: 0px;
  transition: all 0.5s ease;
}

.hide-interval {
  margin-left: -223px;
  transition: all 0.5s ease;
}

.fa-fast-forward,
.fa-fast-backward {
  color: #fff7f3;
  font-size: 37px;
  transition: all 0.2s ease;
}

.fa-stop {
  color: #fff7f3;
  font-size: 31px;
  transition: all 0.2s ease;
}

.image-overlay-forward:hover > .fa-fast-forward,
.image-overlay-play:hover > .fa-play,
.image-overlay-play:hover > .fa-stop,
.image-overlay-backwards:hover > .fa-fast-backward,
.image-overlay-forward:hover > .fa-times-circle:hover {
  color: #5d91e8;
  transition: all 0.2s ease;
}

.fa-times-circle:hover {
  color: #ff759e;
  transition: all 0.2s ease;
}

.image-overlay-controls {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 2;
  cursor: default;
  color: #fff7f3;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
}

.image-overlay-controls-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-overlay-controls-inner > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-overlay-controls-inner > div:first-child {
  height: 80px;
}

.image-overlay-timer {
  color: #fff7f3;
  flex: 1;
  width: 100%;
  top: -4px;
  position: absolute;
  cursor: pointer;
  transition: all 0.2s ease;
}

.fa-times-circle {
  cursor: pointer;
  padding-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff7f3;
  font-size: 47px;
  height: 100%;
  transition: all 0.2s ease;
}

.image-overlay-close {
  opacity: 0;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-overlay-controls-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-overlay-next,
.image-overlay-prev {
  position: absolute;
  height: 100%;
  width: 40px;
  z-index: 1;
  font-size: 68px;
}

.image-overlay-next {
  width: 50%;
  right: 0px;
  z-index: 1;
}

.image-overlay-prev {
  width: 50%;
  left: 0px;
}

.image-overlay-inner > .image-overlay-next:hover {
  opacity: 1;
  color: #5d91e8;
  cursor: pointer;
}

.image-overlay-inner > .image-overlay-prev:hover {
  opacity: 1;
  color: #5d91e8;
  cursor: pointer;
}

.image-overlay-next > i,
.image-overlay-prev > i {
  opacity: 0;
}

.image-overlay:hover > .image-overlay-next > i,
.image-overlay:hover > .image-overlay-prev > i {
  opacity: 1;
}

.image-overlay:hover > .image-overlay-prev {
  left: 0px;
}

.image-overlay:hover > .image-overlay-next {
  right: 0px;
}

.fa-caret-right {
  position: absolute;
  right: -40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-caret-left {
  position: absolute;
  left: -40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-overlay-next-outer {
  height: 100%;
  position: absolute;
  left: calc(100%);
  width: 100%;
  z-index: 999;
}

.image-overlay-prev-outer {
  height: 100%;
  position: absolute;
  right: calc(100%);
  width: 100%;
  z-index: 999;
}

.image-overlay-next-outer:hover
  ~ .image-overlay-inner
  > .image-overlay-next
  > i,
.image-overlay-prev-outer:hover
  ~ .image-overlay-inner
  > .image-overlay-prev
  > i {
  opacity: 1;
  color: #5d91e8;
}

.progress-bar {
  height: 4px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar {
  width: 100%;
  background-color: #ff759e;
}

.progress-five {
  animation: progressAnimationFive 10s;
}

.progress-ten {
  animation: progressAnimationTen 60s;
}

.image-overlay-hide,
.image-fade-hide {
  display: none;
}

.thumb-image > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb-image:first-child > a {
  justify-content: flex-start;
}

.thumb-image:last-child > a {
  justify-content: flex-end;
}

.image-spinner-hide {
  opacity: 0;
  position: absolute;
}

.image-spinner-show {
  opacity: 1;
  position: absolute;
  height: 100%;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-controls-hide {
  display: none;
}

.image-controls-show {
  display: block;
  cursor: pointer;
}

.image-mobile-tut-overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  height: calc(100% - 53px) !important;
  width: calc(100% - 53px) !important;
  margin-left: auto;
  margin-right: auto;
  top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #333;
  opacity: 0.3;
}

.hide-tutorial {
  display: none !important;
}

.image-mobile-tut {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: calc(100% - 53px) !important;
  width: calc(100% - 53px) !important;
  margin-left: auto;
  margin-right: auto;
  top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-mobile-tut > .image-mobile-tut-controll {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff7f3;
  flex: 1;
  height: 100%;
  width: 50%;
  font-size: 19px;
}

.image-mobile-tut-divider {
  height: 100%;
  border: 1px dashed #fff7f3;
  opacity: 0.5;
}

.image-mobile-tut > .image-mobile-tut-controll > .fa-chevron-left {
  margin-right: 10px;
  font-size: 50px;
}

.tut-fade-out {
  animation: fadeOutTut 0.4s linear 1 forwards;
  animation-delay: 3.9s;
}

.tut-fade-out-instant {
  animation: fadeOutTut 0.4s linear 1 forwards;
  animation-delay: 2.5s;
}

.chevron-animate-one {
  animation: shake-one 0.8s linear 1 forwards;
  animation-delay: 2.3s;
}

.chevron-animate-two {
  animation: shake-two 0.8s linear 1 forwards;
  animation-delay: 2.3s;
}

.chevron-animate-one-instant {
  animation: shake-one 0.8s linear 1 forwards;
  animation-delay: 0.7s;
}

.chevron-animate-two-instant {
  animation: shake-two 0.8s linear 1 forwards;
  animation-delay: 0.7s;
}

.image-mobile-tut > .image-mobile-tut-controll > .fa-chevron-right {
  margin-left: 10px;
  font-size: 50px;
}

.show-tutorial {
  display: none;
}
