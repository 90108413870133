.splash {
	display: flex;
	align-items: center;
	flex-direction: column;
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 1;
	background: #fff7f3;
}

.splash-hide {
	animation: fadeOut 1.5s linear forwards;
}

.splash-version {
	position: absolute;
	right: 10px;
	bottom: 10px;
	color: #fdedf3;
}

.fa-code-branch {
	margin-right: 5px;
}

.splash-inner {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
	animation: fadeIn 0.8s linear forwards;
}

.splash-logo {
	font-size: 28px;
	letter-spacing: 1px;
	color: #fdedf3;
	font-family: "Rajdhani", sans-serif;
}

.splash-logo > .fa-camera-retro {
	color: #fdedf3;
	font-size: 36px;
	margin-right: 7px;
}

.splash-dot-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	margin-top: 55px;
}

.splash-fade-out {
	animation: fadeOut 0.8s linear forwards;
}

.spinnerOne {
	margin-top: 55px;
}

.spinnerTwo {
	margin-top: 40px;
}

/* Spinner one */

.spinnerOne-init:before,
.spinnerOne-init:after {
	content: "";
	position: relative;
	display: block;
}

.spinnerOne-init:before {
	animation: spinnerOne 2.05s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
	width: 60px;
	height: 60px;
	background: #333;
	border-radius: 5px;
}

.spinnerOne-init:after {
	animation: shadowSpinnerOne 2.05s cubic-bezier(0.75, 0, 0.5, 1) infinite
		normal;
	bottom: -30px;
	height: 10px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.2);
}

.splash-content {
	margin-bottom: 68px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
}

.spinner {
	width: 60px;
	height: 60px;
	margin-top: 30px;
	background-color: #fdedf3;

	border-radius: 100%;
	-webkit-animation: sk-scaleout 1s infinite ease-in-out;
	animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
	0% {
		-webkit-transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 0;
	}
}

@keyframes sk-scaleout {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
	}
}
