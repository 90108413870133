.main {
	margin-top: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.light,
.light > body > #root > .main > .thumb-grid > .image-large {
	background: #fff7f3;
	transition: all 0.3s ease;
}

.dark,
.dark > body > #root > .main > .thumb-grid > .image-large {
	background: #333;
	transition: all 0.3s ease;
}

.dark * .image-mobile-controls,
.dark * .image-mobile-play > i,
.dark * .image-mobile-close > .fa-times-circle {
	color: #fff7f3 !important;
}

.dark * .image-overlay-next:hover > i,
.dark * .image-overlay-prev:hover > i {
	color: #5d91e8;
}

.prevent-scroll {
	overflow: hidden !important;
}

.thumb-grid {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.thumb-image {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	margin: 0 30px;
}

.thumb-image:first-child {
	justify-content: flex-start;
}

.thumb-image:last-child {
	justify-content: flex-end;
}

.thumb-section {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 60px;
}

.thumb-landscape[src$=".JPG"],
.thumb-portrait[src$=".JPG"] {
	cursor: pointer;
	transition: all 0.6s cubic-bezier(0.73, 0, 0.33, 1), transform 0.15s;
}

.thumb-landscape[src$=".JPG"]:hover,
.thumb-portrait[src$=".JPG"]:hover {
	transform: scale(1.04);
}

.thumb-image > a {
	padding: 8px;
	cursor: default;
}

.light * .scroll-top {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	border: 1px solid #b5cbee;
	position: fixed;
	border-radius: 40px;
	background: #fff7f3;
	bottom: 64px;
}

.dark * .scroll-top {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	border: 1px solid #b5cbee;
	position: fixed;
	border-radius: 40px;
	background: #2c2c2c;
	bottom: 64px;
}

.dark * .scroll-top > .fas {
	font-size: 24px;
	color: #b5cbee;
}

.light * .scroll-top > .fas {
	font-size: 24px;
	color: #5d91e8;
}

.dark * .scroll-top:hover {
	background: #fef3ff;
}

.light * .scroll-top:hover {
	background: #2c2c2c;
}

.dark * .scroll-top:hover > .fas {
	color: #5d91e8;
}

.light * .scroll-top:hover > .fas {
	color: #b5cbee;
}

.scroll-top-show {
	opacity: 1 !important;
	right: 25px;
	transition: all 0.2s ease !important;
}

.scroll-top-hide {
	cursor: auto;
	opacity: 0 !important;
	right: 0px;
	transition: all 0.2s ease !important;
}

.top-anchor {
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 0px;
	height: 0px;
}

.miniSpinner {
	position: fixed;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: fadeinout 3s linear forwards;
}

.miniSpinner-wrapper {
	width: 200px;
	height: 100px;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	opacity: 0.8;
	color: #fff7f3;
	padding: 18px;
	font-size: 19px;
}

.miniSpinner-inner {
	margin-top: 20px;
}

.allow-click {
	pointer-events: all;
}

.prevent-click {
	pointer-events: none;
}
