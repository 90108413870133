.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: none;
	height: 50px;
	margin-top: 60px;
	font-size: 17px;
	background: #2c2c2c;
	color: #b5cbee;
}

.footer-mobile > a {
	color: #b5cbee !important;
}

.footer > .footer-mobile > a > .fa-atom {
	animation: load 3s linear infinite;
	margin: 0 10px 0 7px;
}

.footer > .footer-mobile > a > .fa-fort-awesome {
	margin: 0 4px 0 10px;
	animation: bounce 1s linear infinite;
}

.footer > .footer-mobile > a > .fa-shipping-fast {
	margin: 0 10px;
	animation: bounceBack 1s linear infinite;
}

.footer > .footer-mobile > .fas {
	margin: 0 7px;
}

.footer > .footer-mobile > a:link,
.footer > .footer-mobile > a:visited {
	color: #505050;
	text-decoration: none;
}

.visibility-hidden {
	visibility: hidden;
}
